import { useIntl } from "react-intl";
import React, { useMemo } from "react";
import ProductStockIcon from "../../product/components/ProductStockIcon";
import getProductCover from "../../common/utils/getProductCover";
import boldMatch from "../../common/utils/boldMatch";
import MigelConformity from "modules/product/components/MigelConformity";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

// TODO: Use Thumbor Loader and next/image!
const SuggestedProductListItem = ({
  product,
  searchTerm,
  showMigelConformity,
}) => {
  const { formatMessage } = useIntl();
  // get actual product ID if product came from local storage

  const { media } = product;
  const cover = getProductCover(media);
  const productPageCMSID = findPageIdByFilePath("/product/:slug");
  return (
    <LocalizedLink
      aria-label="Add product"
      key={product?._id}
      page={{ pageId: productPageCMSID, pathname: product?.texts?.slug }}
      className="searched-item py-2 py-md-1 d-flex align-items-center justify-content-start flex-no-wrap w-100 mb-2"
    >
      <>
        <img
          src={cover?.url}
          className="w-max-80px h-max-80px ml-1 mr-3 cursor-pointer object-fit-cover"
          width={320}
          height={320}
          onError={(event) => {
            const element = event?.target;
            if (element instanceof HTMLImageElement) {
              element.src = "/static/img/no-image.png";
              element.onerror = null;
            }
          }}
          alt={product?.texts?.title}
        />
        <div className="d-flex justify-content-between align-items-end row w-100">
          <div className="col-sm-8 align-self-center cursor-pointer">
            <div
              dangerouslySetInnerHTML={{
                __html: boldMatch({
                  value: product?.texts?.title,
                  highlight: searchTerm,
                }),
              }}
            />
            <div className="font-size-small mt-1">
              {formatMessage({
                id: "article_number_label",
                defaultMessage: "Artikel Nr.",
              })}
              :&nbsp;
              {product.sku}
            </div>
            <div className="font-size-small mt-1">
              {showMigelConformity && (
                <MigelConformity {...product?.publicare} />
              )}
            </div>
          </div>
          <div className="col-sm-4 mt-2">
            <small>
              <ProductStockIcon productId={product?._id} />
            </small>
          </div>
        </div>
      </>
    </LocalizedLink>
  );
};

export default React.memo(SuggestedProductListItem);
