import React, { useState } from "react";
import { useIntl } from "react-intl";

import Icon from "../../common/components/Icon";

import useMenu, {
  COCKPIT_HEADER_MENU_NAME,
} from "modules/layout/hooks/useMenu";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import useShopMenu from "modules/layout/hooks/useShopMenu";

const levelClassMap = [
  "pl-3 h3 py-3",
  "pl-3 h4 py-3 color-bg-blueish-light border-menu-top border-menu-top-blueish",
  "pl-4 h5 py-3 color-bg-blueish-lightest color-blue border-menu-top border-menu-top-blueish-light",
  "pl-5 h5 py-3 color-bg-white color-blue border-menu-top border-menu-top-light",
];

const Subtree = ({ pageId, children = {}, title, subtree, level, ...rest }) => {
  const intl = useIntl();

  const [showSubtree, setShowSubtree] = useState(false);

  return children.length > 0 ? (
    <div key={pageId} className="border-menu-top border-menu-top-dark">
      <button
        aria-label="Expand"
        type="button"
        className="no-button w-100 d-flex justify-content-between align-items-center"
        onClick={() => setShowSubtree(!showSubtree)}
      >
        <div
          className={`w-100 d-flex justify-content-between align-items-center ${levelClassMap[level]}`}
        >
          {title}
          <Icon
            icon={showSubtree ? "arrow-button-up" : "arrow-button-down"}
            fill="currentColor"
            className="icon--xs mr-3"
          />
        </div>
      </button>
      {showSubtree ? (
        <div>
          <LocalizedLink
            page={{ pageId }}
            {...rest}
            className={`text-right pr-3 d-block text-decoration-underline ${levelClassMap[level]} pt-0`}
            style={{ fontSize: "1rem", marginTop: "-0.5rem" }}
          >
            {["shop", "assortment"].includes(subtree)
              ? intl.formatMessage({
                  id: "show_all",
                  defaultMessage: "Alle anzeigen",
                })
              : intl.formatMessage({
                  id: "overview",
                  defaultMessage: "Übersicht",
                })}
          </LocalizedLink>

          {children
            .filter(({ url }) => url)
            .map((subNode) => (
              <Subtree
                key={subNode.url.route}
                pageId={subNode.url.route}
                {...subNode}
                level={level + 1}
              />
            ))}
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <LocalizedLink
      page={{ pageId }}
      {...rest}
      className={`border-menu-top border-menu-top-dark d-block ${levelClassMap[level]}`}
    >
      {title}
    </LocalizedLink>
  );
};

const jump = (lang) => {
  const alternateLink = document.querySelector(`head > link[hreflang=${lang}]`);
  const href = alternateLink?.href || `/${lang}`;
  document.location.href = href;
};

const MobileNavigation = ({ doClose, isNavOpen }) => {
  const intl = useIntl();

  const { menu } = useMenu({ name: COCKPIT_HEADER_MENU_NAME });
  const { menu: shopMenu } = useShopMenu({ locale: intl.locale });

  return (
    <div className="mobile-menu-holder" data-is-open={isNavOpen}>
      <button
        aria-label={intl.formatMessage({
          id: "close",
          defaultMessage: "Schliessen",
        })}
        type="button"
        className="no-button mobile-menu-close"
        onClick={doClose}
      >
        <span className="d-none">close</span>
      </button>
      <nav id="menu" className="mobile-menu">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <button
              aria-label={intl.formatMessage({
                id: "close",
                defaultMessage: "Schliessen",
              })}
              type="button"
              className="no-button text-left p-3 d-flex align-items-center"
              onClick={doClose}
            >
              <Icon className="icon--small" icon="close" fill="currentColor" />
              <small className="ml-2">
                {intl.formatMessage({
                  id: "close",
                  defaultMessage: "Schliessen",
                })}
              </small>
            </button>
            <div className="text-right">
              <button
                aria-label="deutsch"
                type="button"
                className="no-button mr-3"
                onClick={() => jump("de")}
              >
                DE
              </button>
              <button
                aria-label="français"
                type="button"
                className="no-button mr-3"
                onClick={() => jump("fr")}
              >
                FR
              </button>
              <button
                type="button"
                aria-label="italiano"
                className="no-button mr-3"
                onClick={() => jump("it")}
              >
                IT
              </button>
            </div>
          </div>

          {shopMenu
            .filter(({ url }) => url)
            .map((node) => (
              <Subtree
                key={node.url.route}
                pageId={node.url.route}
                {...node}
                level={0}
                subtree={node.url.route.split("/")[0]}
              />
            ))}
        </div>

        <div className="border-menu-top border-menu-top-dark pt-4 pl-3">
          {(menu?.links || [])
            ?.filter(({ url }) => url)
            .map(({ url, title }) => (
              <LocalizedLink
                className="d-block mb-3"
                page={{ pageId: url.route }}
                key={url.route}
              >
                {title}
              </LocalizedLink>
            ))}
        </div>
      </nav>
    </div>
  );
};

export default MobileNavigation;
